/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Button, jsx } from 'theme-ui';
import { FiX } from 'react-icons/fi';

const ResourcesFiltersClear = ({ onClick }) => (
  <Button
    sx={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      lineHeight: 1,
      fontSize: 1,
    }}
    onClick={onClick}
    variant="secondary"
  >
    Tout effacer <FiX sx={{ ml: 1 }} />
  </Button>
);

export default ResourcesFiltersClear;
