/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Flex, Box, Button, jsx } from 'theme-ui';
import ResourcesFiltersHeading from './ResourcesFiltersHeading';
import ResourcesFiltersClear from './ResourcesFiltersClear';
import ResourcesFilterGroup from './ResourcesFilterGroup';

const ResourcesFilters = ({ onFilter }) => {
  const [filters, setFilters] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const transitionTime = '0.3s';

  const handleFilter = (key, value) => {
    const newFilters = [...filters];

    if (newFilters.some(f => f.key === key && f.value === value)) {
      const index = newFilters.findIndex(
        f => f.key === key && f.value === value
      );
      newFilters.splice(index, 1);
    } else {
      newFilters.push({ key, value });
    }

    setFilters(newFilters);
    onFilter(newFilters);
  };

  const handleClearFilters = () => {
    setFilters([]);
    onFilter([]);
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: '16px',
        zIndex: 1,
      }}
    >
      <Box sx={{ mb: [0, null, 4] }}>
        <Box mb={[0, null, 4]}>
          <ResourcesFiltersHeading
            onClick={() => setFiltersOpen(!filtersOpen)}
          />
        </Box>
        {filters.length > 0 && (
          <Box sx={{ display: ['none', null, 'block'] }}>
            <ResourcesFiltersClear onClick={handleClearFilters} />
          </Box>
        )}
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          position: ['fixed', null, 'relative'],
          top: 0,
          left: 0,
          bottom: 0,
          width: ['80%', '50%', 'auto'],
          zIndex: [4, null, 1],
          backgroundColor: 'white',
          transition: `transform ${transitionTime}`,
          transform: [
            `translate3d(${filtersOpen ? '0' : '-100%'}, 0, 0)`,
            null,
            'translate3d(0, 0, 0)',
          ],
        }}
      >
        <Box sx={{ p: 3, display: ['block', null, 'none'] }}>
          <Flex
            sx={{
              alignItems: 'center',
              lineHeight: 1,
              fontFamily: 'heading',
              color: 'black',
              fontSize: 3,
            }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filtrer
          </Flex>
          {filters.length > 0 && (
            <ResourcesFiltersClear onClick={handleClearFilters} />
          )}
        </Box>
        <Box
          sx={{
            overflow: 'auto',
            px: [3, null, 0],
            maxHeight: `calc(100vh - ${filters.length > 0 ? '130px' : '60px'})`,
          }}
        >
          <ResourcesFilterGroup
            key="Activities"
            name="Activities"
            nodes={[
              'Yoga',
              'Pilates',
              'Dance',
              'Fitness',
              'Cardio',
              'Meditation Guidée',
              'Soutien Psychologique',
              'Tips pour booster sa forme mentale',
            ]}
            defaultOpen
            filters={filters}
            onFilter={v => handleFilter('activities', v)}
          />
          <ResourcesFilterGroup
            key="Durations"
            name="Durations"
            nodes={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]}
            filters={filters}
            onFilter={v => handleFilter('duration', v)}
          />
          <ResourcesFilterGroup
            key="Platforms"
            name="Platforms"
            nodes={[
              'Instagram',
              'Facebook',
              'YouTube',
              'Google Play Store',
              'App Store',
              'Site Internet',
              'Visioconférence ou téléphone',
            ]}
            filters={filters}
            onFilter={v => handleFilter('platform', v)}
          />
          <ResourcesFilterGroup
            key="Energy Levels"
            name="Energy Levels"
            nodes={['Basse', 'Modérée', 'Forte']}
            filters={filters}
            onFilter={v => handleFilter('energyLevels', v)}
          />
          <ResourcesFilterGroup
            key="Benefits"
            name="Benefits"
            nodes={[
              'Booster son énergie calmement',
              "Parler pour se libérer d'un poids",
              'Se défouler physiquement',
              "Soulager l'anxiété",
              'Réduire ce maudit stress',
              'Mieux dormir',
            ]}
            filters={filters}
            onFilter={v => handleFilter('benefits', v)}
          />
          <ResourcesFilterGroup
            key="Experience Levels"
            name="Experience Levels"
            nodes={[
              'Débutant.e',
              'Intermédiaire',
              'Confirmé.e',
              'Tous niveaux',
            ]}
            filters={filters}
            onFilter={v => handleFilter('experienceLevels', v)}
          />
          <ResourcesFilterGroup
            key="Content Types"
            name="Content Types"
            nodes={[
              'Live Stream',
              'Video',
              'App',
              'Téléconsultations',
              'Articles',
            ]}
            filters={filters}
            onFilter={v => handleFilter('contentType', v)}
          />
        </Box>
        <Flex
          sx={{
            display: ['flex', null, 'none'],
            m: 3,
            flexShrink: 0,
          }}
        >
          <Button
            sx={{ flex: '1', flexShrink: 0 }}
            onClick={() => setFiltersOpen(false)}
          >
            Appliquer
          </Button>
          <Button
            sx={{
              flex: '1',
              flexShrink: 0,
              ml: 2,
            }}
            onClick={() => setFiltersOpen(false)}
            variant="secondary"
          >
            Fermer
          </Button>
        </Flex>
      </Flex>
      <Box
        onClick={() => setFiltersOpen(false)}
        sx={{
          display: filtersOpen ? 'block' : 'none',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 3,
          backgroundColor: [`rgba(0, 0, 0, 0.2)`, null, 'transparent'],
        }}
      />
    </Box>
  );
};

export default ResourcesFilters;
