import React from 'react';
import {
  FiInstagram,
  FiFacebook,
  FiPhone,
  FiYoutube,
  FiSmartphone,
  FiGlobe,
} from 'react-icons/fi';

const ResourceCardPlatformIcon = ({ platforms, ...rest }) => {
  const platform =
    Array.isArray(platforms) && platforms.length > 0 ? platforms[0] : null;
  let Icon = FiGlobe;

  switch (platform) {
    case 'Instagram':
      Icon = FiInstagram;
      break;
    case 'Facebook':
      Icon = FiFacebook;
      break;
    case 'YouTube':
      Icon = FiYoutube;
      break;
    case 'Visioconférence ou téléphone':
      Icon = FiPhone;
      break;
    case 'Google Play Store':
    case 'App Store':
      Icon = FiSmartphone;
      break;
    default:
      Icon = FiGlobe;
  }

  return <Icon {...rest} />;
};

export default ResourceCardPlatformIcon;
