/** @jsx jsx */
import { useState, useRef } from 'react';
import { Grid, Heading, Box, Text, Image, jsx, Flex } from 'theme-ui';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ResourcesFilters from '../components/ResourcesFilters';
import ResourceCard from '../components/ResourceCard';
import emptyIllustration from '../images/empty-illustration.svg';

function getFilteredResources(resources = [], filters = []) {
  if (filters.length === 0) return resources;

  return resources.filter(resource => {
    return filters.every(f => {
      if (!resource[f.key]) return false;

      return typeof resource[f.key] === 'number'
        ? resource[f.key] === f.value
        : resource[f.key].includes(f.value);
    });
  });
}

const ResourcesPageTemplate = ({ location, data }) => {
  const [resources, setResources] = useState(data.resources.nodes);
  const mainRef = useRef();

  const handleFilter = filters => {
    const filteredResources = getFilteredResources(
      data.resources.nodes,
      filters
    );

    setResources(filteredResources);
    window.scrollTo(0, mainRef.current?.offsetTop - 16 || 0);
  };

  return (
    <Layout location={location}>
      <SEO title="Tous les liens bien-être" />
      <Box
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: [2, 3, 4, 11],
          mb: [2, 3, 6, 8],
        }}
      >
        <Heading
          as="h1"
          sx={{
            fontSize: [4, 5, 8],
            mb: 4,
          }}
        >
          {data.title.value}
        </Heading>
        <Text
          sx={{
            variant: 'text.heading',
            fontSize: [2, 4],
            lineHeight: 'body',
          }}
        >
          {data.subtitle.value}
        </Text>
      </Box>
      <Box
        as="nav"
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: [2, 3, 2, 4],
          position: 'relative',
        }}
      >
        <ResourcesFilters onFilter={handleFilter} />
      </Box>
      <Box
        as="main"
        ref={mainRef}
        sx={{
          gridColumnStart: [1, null, 2, 4],
          gridColumnEnd: [2, 3, 5, 13],
        }}
      >
        {resources.length > 0 ? (
          <Grid columns={[1, 2, null, 3]} gap={5}>
            {resources.map(resource => (
              <ResourceCard key={resource.id} resource={resource} />
            ))}
          </Grid>
        ) : (
          <Flex
            sx={{
              position: 'sticky',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              margin: '0 auto',
              maxWidth: '500px',
              p: [4, 2],
              top: 0,
            }}
          >
            <Image src={emptyIllustration} alt="" sx={{ mb: 5 }} />
            <Heading sx={{ mb: 3, fontSize: [3, 4] }}>
              Oops, désolé.e… Aucun résultat trouvé
            </Heading>
            <Text>
              Nous travaillons dur pour ajouter plus de ressources à mindkit. En
              attendant, pourquoi ne pas essayer d'ajuster ou de changer les
              filtres ?
            </Text>
          </Flex>
        )}
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    title: sanityMicrocopy(key: { eq: "Home: Title" }) {
      value
    }
    subtitle: sanityMicrocopy(key: { eq: "Home: Subtitle" }) {
      value
    }
    resources: allSanityResource {
      nodes {
        id
        name
        url
        platform
        experienceLevels
        energyLevels
        duration
        contentType
        benefits
        activities
      }
    }
  }
`;

export default ResourcesPageTemplate;
