/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Heading, Button, jsx } from 'theme-ui';
import { FiSliders } from 'react-icons/fi';

const ResourcesFiltersHeading = ({ onClick }) => {
  return (
    <>
      <Heading
        as="h3"
        sx={{
          display: ['none', null, 'flex'],
          alignItems: 'center',
          lineHeight: 1,
          fontSize: [2, 3],
        }}
        onClick={onClick}
      >
        Filtrer
      </Heading>
      <Button
        sx={{
          display: ['flex', null, 'none'],
          alignItems: 'center',
          lineHeight: 1,
          fontSize: [2, 3],
        }}
        variant="secondary"
        onClick={onClick}
      >
        Filtrer
        <FiSliders sx={{ fontSize: 2, ml: 2 }} />
      </Button>
    </>
  );
};

export default ResourcesFiltersHeading;
