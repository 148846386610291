/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Flex, Box, Heading, Text, jsx } from 'theme-ui';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { FiExternalLink } from 'react-icons/fi';
import ResourceCardPlatformIcon from './ResourceCardPlatformIcon';

const getBorderColor = experienceLevels => {
  const level =
    Array.isArray(experienceLevels) && experienceLevels.length > 0
      ? experienceLevels[0]
      : null;

  switch (level) {
    case 'Débutant.e':
      return 'green';
    case 'Intermédiaire':
      return 'pink';
    case 'Confirmé.e':
      return 'orange';
    default:
      return 'blue';
  }
};

const ResourceCard = ({ resource }) => {
  const { activities, experienceLevels, energyLevels, benefits } = resource;
  const borderColor = getBorderColor(resource.experienceLevels);

  return (
    <Box
      as="article"
      sx={{
        height: '100%',
        backgroundColor: 'gray1',
        borderRadius: 1,
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translate3d(0, -5px, 0)',
        },
      }}
    >
      <OutboundLink
        href={resource.url}
        sx={{ textDecoration: 'none', color: 'inherit' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box
          sx={{
            backgroundColor: borderColor,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            p: 2,
          }}
        ></Box>
        <Box sx={{ p: 3 }}>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <FiExternalLink sx={{ fontSize: [2, 3], color: 'black' }} />
          </Flex>
          <Flex sx={{ justifyContent: 'space-between', mb: 2 }}>
            <ResourceCardPlatformIcon
              platforms={resource.Platforms}
              sx={{ fontSize: 4, color: 'black' }}
            />
          </Flex>
          <Heading
            sx={{
              variant: 'text.heading',
              fontSize: 3,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              mb: 2,
            }}
          >
            {resource.name}
          </Heading>
          <Text
            sx={{
              fontSize: 1,
              mb: 2,
            }}
          >
            {resource.contentType}
            {activities.length > 0 && ` • ${activities.join(', ')}`}
            {experienceLevels.length > 0 && ` • ${experienceLevels.join(', ')}`}
            {energyLevels.length > 0 && ` • ${energyLevels.join(', ')}`}
          </Text>
          <Text sx={{ fontSize: 2 }}>
            {benefits.length > 0 && <>Aide à {benefits.join(', ')}</>}
          </Text>
        </Box>
      </OutboundLink>
    </Box>
  );
};

export default ResourceCard;
