/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Heading, Flex, Box, jsx } from 'theme-ui';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import ResourcesFilter from './ResourcesFilter';

const translation = {
  Activities: 'Activité',
  Durations: "Durée de l'activité",
  Platforms: 'Plateforme utilisée',
  'Energy Levels': "Niveau d'énergie",
  Benefits: 'Les bénéfices',
  'Content Types': 'Types de liens',
  'Experience Levels': 'Ton niveau',
};

const ResourcesFilterGroup = ({
  defaultOpen = false,
  name,
  nodes,
  filters,
  onFilter,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            mb: 3,
          }}
          onClick={() => {
            setIsOpen(!isOpen);
            trackCustomEvent({
              category: 'Filter Heading',
              action: 'Click',
              label: name,
            });
          }}
        >
          <Heading
            as="h3"
            sx={{
              variant: 'text.heading',
              fontSize: 2,
              pr: 2,
            }}
          >
            {translation[name]}
          </Heading>
          {isOpen ? (
            <FiChevronUp sx={{ fontSize: 4, flexShrink: 0 }} />
          ) : (
            <FiChevronDown sx={{ fontSize: 4, flexShrink: 0 }} />
          )}
        </Flex>
        {isOpen && (
          <Box>
            {nodes.map(node => (
              <ResourcesFilter
                key={node}
                name={node}
                isSelected={filters.some(f => f.value === node)}
                onClick={() => {
                  onFilter(node);
                  trackCustomEvent({
                    category: 'Filter',
                    action: 'Click',
                    label: node,
                  });
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ResourcesFilterGroup;
