/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Flex, Text, jsx } from 'theme-ui';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

const ResourcesFilter = ({ name, isSelected, onClick }) => {
  return (
    <Flex
      sx={{ alignItems: 'center', mb: 2, cursor: 'pointer', color: 'black' }}
      onClick={onClick}
    >
      {isSelected ? (
        <FiCheckSquare sx={{ fontSize: 4, mr: 2, flexShrink: 0 }} />
      ) : (
        <FiSquare sx={{ fontSize: 4, mr: 2, flexShrink: 0 }} />
      )}
      <Text
        sx={{
          fontSize: 1,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {name}
      </Text>
    </Flex>
  );
};

export default ResourcesFilter;
